$primaryColor: #003366;
.towing-service-container {

  padding: 20px;
  max-width: 800px;
  margin: 20px auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;

  h2 {
    color: $primaryColor;
    margin-bottom: 20px;
  }
  .call-now {
    background-color: #f8f9fa;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 20px;

    .call-icon {
      margin-right: 10px;
      color: #007bff;
    }

    a {
      text-decoration: none;
      color: inherit;

      h2 {
        margin: 0;
        font-size: 1.5em;
        color: #007bff;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  p {
    margin-bottom: 15px;
    line-height: 1.5;
  }

  .areas-served {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 10px;
        color: #007bff;
      }
    }
  }
}
