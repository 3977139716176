.napa-auto-care {

    max-width: 800px;
    margin: auto;
    padding: 20px;
}

.napa-auto-care h2 {
    //center align
text-align: center;
}
.napa-auto-care h1, .napa-auto-care h2, .napa-auto-care h3 {
    color: #003366; /* Dark blue color */
}

.napa-auto-care p {
    color: #333; /* Dark gray color */
    line-height: 1.6;
}

.contact-info {
    font-weight: bold;
    margin-top: 20px;
}
