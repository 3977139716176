$primaryColor: #003366;
$breakpoint-md: 768px;


.business-info {
  @media (max-width: $breakpoint-md) {
    .business-info {
      margin-top: 10px; /* Reduced margin for mobile view */
      padding-top: 10px; /* Reduced top padding for mobile view */
    }
  }

  color: $primaryColor;
  padding: 10px 20px;
  max-width: 800px;
  margin: 0 auto 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
