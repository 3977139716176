$primaryColor: #003366;
$secondaryColor: #343a40;
$breakpoint-md: 768px;

.slogan-container {
  text-align: center;
  margin: 30px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 800px;

  h1 {
    color: $primaryColor;
    font-size: 28px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  p {
    color: $secondaryColor;
    font-size: 18px;
    line-height: 1.5;
  }
}

.Home {
  text-align: center;

  h2 {
    color: $primaryColor;
    margin-bottom: 15px;
  }

  .video-description, .slideshow-description {
    color: $secondaryColor;
    line-height: 1.6;

    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: $breakpoint-md) {
    .video-description, .slideshow-description {
      padding: 0 10px;
    }
  }
}

.business-info h2 {
  margin-top: 0;
}
.vision-link {
  color: $primaryColor;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: darken($primaryColor, 10%);
    text-decoration: underline;
  }
}
