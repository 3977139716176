$primaryColor: #003366;
.business-info {

    padding: 20px;
    max-width: 800px;
    margin: 20px auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    section {
        width: 100%;

        text-align: center;

        h2 {

            color: $primaryColor;
            margin-bottom: 10px;
        }

        ul {
            list-style-type: none;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;

            li {
                margin-bottom: 5px;
                font-size: 16px;
                strong {
                    color: #006400;
                }
            }
        }

        p, a {

            font-size: 20px;
            line-height: 1.5;
            color: inherit;
            text-decoration: underline;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
