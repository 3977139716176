.bg-products {

    max-width: 800px;
    margin: auto;
    padding: 20px;
}

.bg-products h1, .bg-products h2, .bg-products h3 {

    color: #003366; /* Dark blue color */
}

.services-list {
    list-style-type: none;
    padding: 0;
}

.services-list li {
    background-color: #f0f0f0;
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
}

.video-link {
    display: block;
    color: #0066cc;
    text-decoration: none;
    margin-bottom: 5px;
}

.video-link:hover {
    text-decoration: underline;
}
