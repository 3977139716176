$primaryColor: #003366;

.about-container {
  padding: 20px;
  max-width: 800px;
  margin: 20px auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;

  section {
    margin-bottom: 30px;
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    h2 {
      color: $primaryColor;
      margin-bottom: 15px;
      text-align: center;
    }

    p {
      margin-bottom: 15px;
      font-size: 16px;
      line-height: 1.5;
      color: #333;
    }

    h3 {
      color: darken($primaryColor, 10%);
      margin-bottom: 10px;
      font-weight: bold;
      text-align: center;
    }
  }

  .vision {
    text-align: center;

    .vision-content {
      h3 {
        font-size: 20px;
        color: $primaryColor;
        font-weight: bold;
        margin: 10px 0;
      }

      p {
        font-size: 16px;
        color: #333;
        text-align: center;
        margin: 10px 0;
      }
    }
  }

  .mission-values {
    text-align: center;

    .mission {
      h3 {
        font-size: 18px;
        color: darken($primaryColor, 10%);
        margin-bottom: 10px;
        font-weight: bold;
      }

      p {
        font-size: 16px;
        color: #333;
        text-align: center;
        margin: 5px 0;
      }
    }

    .values {
      h3 {
        font-size: 18px;
        color: darken($primaryColor, 10%);
        margin-bottom: 10px;
        font-weight: bold;
      }

      .value-item {
        font-size: 16px;
        color: #333;
        background-color: #f4f4f4;
        padding: 15px;
        margin: 10px 0;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        text-align: center;
        font-weight: 500;
      }

      .value-item:hover {
        background-color: lighten($primaryColor, 40%);
        color: $primaryColor;
        transition: background-color 0.3s ease, color 0.3s ease;
      }
    }
  }

  .about-us {
    p {
      color: #333;
    }

    h3 {
      border-bottom: 2px solid $primaryColor;
      padding-bottom: 5px;
    }
  }
}
