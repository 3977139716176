$primaryColor: #003366;
$secondaryColor: #343a40;
$breakpoint-md: 768px;

.autorepair-intro, .autorepairs-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin: 20px auto 30px; // Added 30px bottom margin
  max-width: 800px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;

  h2 {
    color: $primaryColor;
    margin-bottom: 15px;
  }

  p {
    color: #333;
    line-height: 1.6;
    margin-bottom: 20px;
  }
}

.autorepairs-container {
  background-color: #f4f4f4;

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 0.5rem;
      color: $secondaryColor;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 0.5rem;
        color: $primaryColor;
      }
    }
  }
}

.call-now {
  background-color: #f8f9fa;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-bottom: 20px;

  .call-icon {
    margin-right: 10px;
    color: #007bff;
  }

  a {
    text-decoration: none;
    color: inherit;

    h2 {
      margin: 0;
      font-size: 1.5em;
      color: #007bff;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
