.terms-of-use {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 800px;
    margin: 20px auto;
    background-color: #fff; // Adds a white background
    border-radius: 10px; // Rounds the corners of the card
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Adds a subtle shadow for depth
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        text-align: center;
        margin-bottom: 20px;
    }

    h2 {
        font-size: 24px;
        color: #007bff;
        margin-bottom: 10px;
    }

    p, ul {
        margin-bottom: 15px;
        line-height: 1.6;
    }

    ul {
        list-style-type: disc;
        margin-left: 20px;
    }
}
