.privacy-policy {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 800px;
  margin: 20px auto;
  background-color: #fff; // Adds a white background
  border-radius: 10px; // Rounds the corners of the card
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Adds a subtle shadow for depth
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    text-align: center;
    margin-bottom: 20px;
  }

  section {
    width: 100%;
    text-align: left; // Adjusted for readability in text-heavy sections

    h2 {
      font-size: 24px;
      color: #007bff;
      margin-bottom: 10px;
    }

    p {
      font-size: 18px; // Adjust font size for readability
      line-height: 1.6; // Line height for easier reading
      margin-bottom: 15px;
    }
  }
}
