$primary-color: #000000;
$secondary-color: #ffffff;

$breakpoint-md: 768px;
$button-background-color: rgb(250, 199, 37);
$button-text-color: blue;
$button-hover-background-color: $button-text-color;
$button-hover-text-color: $button-background-color;

:root {
  --header-padding: 1em;
  --logo-max-height: 175px;
  --partner-logos-max-height: 40px;
  --service-button-padding: 0.75em 3em;
  --service-button-font-size: 1.2em;
  --header-height: 60px;
  --header-height-lg: 40px;
  --mobile-header-height: 50px;
  --menu-icon-size: 1.0em;
  --contact-icon-spacing: 10px; // added variable for contact icon spacing
  --contact-icon-padding-right: 5px; // added variable for contact icon padding
}
// Media Query for Large Screens
@media (min-width: $breakpoint-md) {
  :root {
    --header-height: var(--header-height-lg);
  }
}

// Media Query for Small Screens
@media (max-width: $breakpoint-md) {
  :root {
    --header-padding: 0.3em; // Reduced padding
    --logo-max-height: 175px; // Reduced logo size
    --partner-logos-max-height: 40px; // Reduced partner logo size
    --service-button-padding: 0.5em 1em; // Reduced button padding
    --service-button-font-size: 0.8em; // Reduced button font size
    --header-height: var(--mobile-header-height);
    --contact-icon-spacing: 5px; // Reduced spacing
    --contact-icon-padding-right: 3px; // Reduced padding
  }

  .header-container {
    position: relative;
    z-index: 5;
  }

  .service-buttons .service-btn {
    padding: var(--service-button-padding);
    font-size: var(--service-button-font-size);
  }

  .branding .HeaderBar-logo img {
    max-width: 100%;
    height: auto;
  }

  .partner-logos img {
    max-height: var(--partner-logos-max-height);
  }
}

.header-container {

  background-size: cover;
  position: relative;
  overflow: hidden;
  padding-bottom: 20px;
  .header-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top; // Focus on the top of the image
    z-index: -1;
  }
}
.HeaderBar nav ul {
  padding: 0; // Remove padding from the unordered list
}

.HeaderBar nav ul li {
  margin: 0; // Remove margin from list items
  list-style-type: none; // Remove bullet points
  width: 100%; // Full width
}

.HeaderBar nav ul li a {
  display: block; // Block display to fill the parent
  width: 100%; // Full width of the parent

  text-align: center; // Center the text
  background-color: transparent; // Optional: for better visibility
  color: inherit; // Inherit text color from parent
  text-decoration: none; // Remove underline from links
}

.HeaderBar {

  nav ul li a, .contact-icons a {
    text-overflow: ellipsis;
    flex-shrink: 1;
    white-space: nowrap; // Prevents line breaks in navigation links
    overflow: hidden; // Hide the overflow

  }
  background-color: $primary-color;
  color: $secondary-color;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;

  height: var(--header-height);
  z-index: 10;

  @media (max-width: $breakpoint-md) {
    padding: var(--header-padding);
    bottom: 0;
    top: auto;

    .menu-icon {
      display: block;
      position: absolute;
      top: 10px;
      left: 10px;
      font-size: var(--menu-icon-size);

    }

    nav {
      position: absolute;
      bottom: calc(2px + var(--header-height)); // Use var(--header-height) for desktop
      width: fit-content;

      @media (max-width: $breakpoint-md) {
        bottom: calc(2px + var(--mobile-header-height));
        background-color: rgba(0, 0, 0, 0.9); // Background for dropdown
      }
      background-color: $primary-color; // Desktop background color
      ul {
        flex-direction: column;
        align-items: start;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.9); // Background for dropdown
        padding: 0;
        margin-top: 45px; // Adjust based on the header height
        li {
          border-bottom: 1px solid $secondary-color;
          &:last-child {
            border-bottom: none; // Remove border from the last item
          }
          width: 100%;
          text-align: left; // Align to the left for better readability
          padding: 15px; // More padding for easier touch
        }
      }
      &.active {
        display: block;
      }
    }

    .right-icons {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .menu-icon {
    background: none;
    border: none;
    cursor: pointer;
    color: $secondary-color;
    display: none;
    font-size: var(--menu-icon-size);

    @media (max-width: $breakpoint-md) {
      display: block;
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }

  nav {
    //for small screen
    @media (max-width: $breakpoint-md) {
      position: absolute;
      bottom: calc(2px + var(--header-height));
      width: fit-content;
      background-color: $primary-color;
      padding: 5px 10px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      display: none;
      z-index: 15;

      &.active {
        display: block;
      }
    }
    ul {
      list-style: none;
      display: flex;
      margin: 0;
      padding: 0;
      @media (max-width: $breakpoint-md) {
        list-style: none;
        margin: 0;
        padding: 0;
        white-space: nowrap;
      }

      li {
        margin: 0 10px;
        font-size: 1em;

        @media (max-width: $breakpoint-md) {
          margin: 0 5px;
          font-size: 0.8em;
        }

        a {
          text-decoration: none;
          color: $secondary-color;

          &:hover {
            color: lighten($secondary-color, 10%);
          }

          &.active {
           // change color to secondary-color
            color: $button-background-color;
            text-decoration: underline;
          }
        }
        .right-icons {
          position: absolute;
          top: 10px;
          right: 10px;

      }
    }
  }



    .contact-icons {
      display: flex;
      a {
        color: $secondary-color;
        margin-left: var(--contact-icon-spacing);
        padding-right: var(--contact-icon-padding-right);
      }

      &:hover {
        color: #007bff; // Change hover color as needed
      }

      .FontAwesomeIcon {
        margin-right: 8px;
      }

      .icon-label {
        font-size: 1rem; // Adjust font size as needed
        display: inline-block;
      }
    }
  }
}
.branding {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--header-height);
  position: relative;
  z-index: 2;
  flex-wrap: wrap;

  @media (min-width: $breakpoint-md) {
    flex-direction: row;
    justify-content: space-evenly;
  }

  @media (max-width: $breakpoint-md) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0;
    padding-top: 0;

  }

  .HeaderBar-logo {
    margin-top: 20px;
    @media (max-width: $breakpoint-md) {
      margin-top: 10px;
      margin-bottom: 25px;
    }
  }

  .HeaderBar-logo img, .partner-logos img {
    max-width: 100%;
    height: auto;
    max-height: var(--logo-max-height);
    margin: 0px;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  }

  .partner-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 10px;


    a {
      margin: 0 10px;

      img {
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.1);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }
      }
    }
    @media (max-width: $breakpoint-md) {
      a {
        margin: 5px; // Reduce the margin for smaller screens
      }
    }
  }
}

.service-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 50px 0;

  .service-btn {

    max-height: 60px;
    flex: 0 0 calc(25% - 10px);
    text-align: center;
    padding: var(--service-button-padding);
    font-size: var(--service-button-font-size);
  }

  @media (max-width: $breakpoint-md) {
    .service-btn {

      flex-basis: calc(50% - 10px);
    }
  }

  @media (max-width: 500px) {

    .service-btn {
      flex-basis: 100%; // Full width for very small screens
    }
  }
}
.service-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Ensure buttons can wrap onto the next line */
  gap: 10px;
  padding: 20px 0;
}

.service-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; // Ensures buttons wrap onto the next line
  gap: 10px;
  padding: 30px 0; // Increased padding to move buttons further down

  @media (max-width: $breakpoint-md) {
    padding: 50px 0; // Even more padding for mobile
  }
}

.service-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 30px 0; // Increased padding for better spacing on mobile

  @media (max-width: $breakpoint-md) {
    padding: 50px 0; // Additional padding on mobile for better reachability
  }
}

.service-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; // Ensures buttons can wrap onto the next line
  gap: 10px;
  padding: 30px 0; // Increased padding for better spacing on mobile

  @media (max-width: $breakpoint-md) {
    padding: 50px 0; // Additional padding on mobile for better reachability
  }
}

.service-buttons {

  @media (max-width: $breakpoint-md) {
    display: grid;
    gap: 10px;
    justify-items: center; // Centering the buttons in their grid cells
   padding-bottom: 0px; // Increased padding for better spacing on mobile
    padding-top: 50px;
    // For mobile screens, switch to two columns
    grid-template-columns: repeat(2, 1fr);

  }

  .service-btn {
    background-color: $button-background-color;
    color: $button-text-color;
    border: none;
    border-radius: 5px;
    padding: var(--service-button-padding);
    font-size: var(--service-button-font-size);
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      background-color: $button-hover-background-color;
      color: $button-hover-text-color;
    }
  }
}






.icon-label {


  font-size: 0.8em; // Adjust size as needed
  margin-left: 5px; // Space between icon and label
}
.contact-icons a:hover .icon-label {
  color: lighten($button-text-color, 20%); // Slightly lighten on hover
}
.contact-icons {
  display: flex;
  justify-content: space-around; // Adjust spacing
  align-items: center;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
