$primaryColor: #003366;
.tire-services {

    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    max-width: 600px;
    margin: 20px auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tire-services h2 {
    color: $primaryColor;
    margin-bottom: 15px;
}

.tire-services p {
    color: #333; /* Dark gray color */
    line-height: 1.6;
    margin-bottom: 20px;
}

.call-action {
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    display: inline-block;
    margin-bottom: 20px;
}

.call-action span {
    font-size: 1.2em;
    color: #333;
}

.call-action a {
    font-size: 1.2em;
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.call-action a:hover {
    text-decoration: underline;
}

.call-now {
    background-color: #f8f9fa;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 20px;

    .call-icon {
        margin-right: 10px;
        color: #007bff;
    }

    a {
        text-decoration: none;
        color: inherit;

        h2 {
            margin: 0;
            font-size: 1.5em;
            color: #007bff;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

p {
    margin-bottom: 15px;
    line-height: 1.5;
}
