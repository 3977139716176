.slideshow-container {
  .image-gallery {
    .image-gallery-slide {
      text-align: center;
      background: #000;
      img {
        width: auto;
        max-height: 60vh;
        object-fit: cover;
        padding: 5px;
        margin: auto;
      }
    }

    .image-gallery-thumbnails-wrapper {
      .image-gallery-thumbnail {
        border: 2px solid transparent;
        &.active, &:hover, &:focus {
          border: 2px solid #333;
        }
      }
    }

    .image-gallery-description {
      background: rgba(0, 0, 0, 0.5);
      bottom: 0;
      position: absolute;
      width: 100%;
      color: #fff;
      padding: 10px;
      text-align: center;
      font-size: 14px;
    }

    @media (max-width: 600px) {
      .image-gallery-slide {
        img {
          max-height: 40vh;
          padding: 3px;
        }
      }

      .image-gallery-thumbnails-wrapper {
        display: none;
      }

      .image-gallery-description {
        font-size: 12px;
        padding: 5px;
      }
    }
  }
}
