.footer {
  background-color: #f8f9fa;
  padding: 20px 0;
  text-align: center;

  @media (max-width: 768px) {
    padding-bottom: 70px;
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  .footer-links .FontAwesomeIcon {
    margin-right: 8px; /* Adjust this value as needed */
  }
  .footer-links {
    list-style: none;
    padding: 0;
    margin-bottom: 10px;

    li {
      display: inline-block;
      margin: 0 10px;
      vertical-align: middle;

      a {
        color: #003366; // Changed to a darker blue for better contrast
        text-decoration: none;
        line-height: 1.5;
        display: flex;
        align-items: center;
        padding: 5px;

        &:hover {
          text-decoration: underline;
        }

        .fa-facebook, .fa-google {
          margin-right: 8px;
        }
      }
    }
  }


  .footer-text {
    color: #333333; // Changed to a darker gray for better contrast
    font-size: 14px;
  }
}
