$primaryColor: #003366;
.state-inspection-container {

  padding: 20px;
  max-width: 800px;
  margin: 20px auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h2 {
    color: $primaryColor;
    text-align: center;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 15px;
    line-height: 1.5;
  }

  .inspection-details {
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;

    h2 {
      margin-bottom: 10px;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        svg {
          margin-right: 10px;
          color: $primaryColor;
        }
      }
    }
  }

  .contact-inspection {
    text-align: center;

    .call-inspection {
      background-color: #007bff;
      color: white;
      padding: 10px 15px;
      border-radius: 5px;
      text-decoration: none;
      font-weight: bold;

      &:hover {
        background-color: darken(#007bff, 10%);
      }
    }
  }
}
